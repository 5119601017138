import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router)
const routers = [
    // {
    //     path: "/login",
    //     redirect: "/login"
    // },
    {
        path: "/",
        // redirect: "/select-system",
        component: () => import("@/views/select-system/select-system"),
        meta: {
            title: "选择模式",
            keepAlive:false
        }
    },
    {
        path: "/welcome",
        component: () => import("@/views/welcome/welcome"),
        meta: {
            title: "欢迎",
            keepAlive:false
        }
    },
    {
        path: "/openCard",
        component: () => import("@/views/openCard/openCard"),
        meta: {
            title: "自助开牌",
            keepAlive:false
        }
    },
    {
        path: "/codeBox",
        component: () => import("@/views/codeBox/codeBox"),
        meta: {
            title: "自助开牌",
            keepAlive:false
        }
    },
    {
        path: "/home",
        component: () => import("@/views/home/home"),
        meta: {
            title: "首页",
            keepAlive:false
        }
    },
    {
        path: "/verification",
        meta: {
            title: "核销",
            keepAlive:false
        },
        component: () => import("@/views/home/verification/verification")
    },
    {
        path: "/verificationList",
        meta: {
            title: "核销列表",
            keepAlive:false
        },
        component: () => import("@/views/home/verificationList/verificationList")
    },
    {
        path: "/result",
        meta: {
            title: "核销结果",
            keepAlive:false
        },
        component: () => import("@/views/home/result/result")
    },
    {
        path: "/scan",
        meta: {
            title: "自助结账",
            keepAlive:false
        },
        component: () => import("@/views/home/scan/scan")
    },
    {
        path: "/payment",
        meta: {
            title: "自助结账",
            keepAlive:false
        },
        component: () => import("@/views/home/payment/payment")
    },
    {
        path: "/list",
        meta: {
            title: "自助结账",
            keepAlive:false
        },
        component: () => import("@/views/home/list/list")
    },
    {
        path: "/login",
        component: () => import("@/views/login/login"),
        meta: {
            title: "登录"
        }
    },
    {
        path: "/prepayment",
        meta: {
            title: "先付模式",
            keepAlive:false
        },
        component: () => import("@/views/prepayment/prepayment")
    },
]
const router = new Router({
    mode: 'hash',
    routes: routers
})
export default router;