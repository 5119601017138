import axios from "axios";
import router from "../router";
import { Toast } from 'vant';
// 创建axios实例
Toast.setDefaultOptions({ duration: 2000 });
axios.defaults.timeout = 30000;
var baseURL = process.env.NODE_ENV == 'development' ? 'http://192.168.1.21:31000' : 'https://api.spa.gshis.com'
axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'http://192.168.1.21:31000' : 'https://api.spa.gshis.com'
// axios.defaults.baseURL = 'http://21n2c53681.iask.in:31000';
// axios.defaults.baseURL = 'https://api.spa.gshis.com';
// https://api.spa.gshis.com
//http request 拦截器
var noToken = baseURL+'/receptionkeycard/1.0/Reception/ManualOpen'
const noTokenRequestUrls = [noToken]
axios.interceptors.request.use(
    config => {
        var token = localStorage.getItem('token')
        if (!noTokenRequestUrls.includes(config.url)) {
            if (token) {
                let tokenId = token;
                config.headers = {
                    'Content-Type': 'application/json',
                    'access_token': tokenId,
                    'x_req_application_id': 1,
                    'x_req_terminal': localStorage.getItem('terminalId') ? localStorage.getItem('terminalId') : 9,
                    'x_req_store_id': localStorage.getItem('storeId')
                };
            }
        }

        return config;
    },
    error => {
        return Promise.reject(err);
    }
);

//http response 拦截器
axios.interceptors.response.use(
    response => {
        if (response.data.errCode === 2) {
            router.push({
                path: "/login",
                querry: { redirect: router.currentRoute.fullPath }//从哪个页面跳转
            })
        }
        return response;
    },
    error => {
        if (error.response) {
            console.log(error);
            Toast(error.response.data.message);
            if (error.response.status == 401) {
                router.push({
                    path: "/login",
                    querry: { redirect: router.currentRoute.fullPath }//从哪个页面跳转
                })
            }
        }
        return Promise.reject(error)
    }
);




/**
 * 封装get方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function fetch(url, params = {}) {
    return new Promise((resolve, reject) => {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
        });

        axios.get(url, {
            params: params
        })
            .then(response => {
                resolve(response.data);
                Toast.clear();
            })
            .catch(err => {
                reject(err);
            })
    })
}


/**
 * 封装get方法 不待loading
 * @param url
 * @param params
 * @returns {Promise}
 */
export function fetchNo(url, params = {}) {

    return new Promise((resolve, reject) => {

        axios.get(url, {
            params: params,
        }).then(response => {
            resolve(response.data);

        }).catch(err => {
            reject(err);
        })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data, responseType = {}) {
    return new Promise((resolve, reject) => {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
        });
        axios.post(url, data, responseType).then(response => {

            //关闭进度条
            Toast.clear();
            resolve(response.data);

        }, err => {
            reject(err)
        })
    })
}

/**
 * 封装patch请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        Toast.loading({
            message: '加载中...',
            forbidClick: true,
        });
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
                Toast.clear();
            }, err => {
                /* msag();*/
                reject(err)
            })
    })
}
/**
 * 封装delete请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function deletes(url, data = {}) {
    return new Promise((resolve, reject) => {
        Toast.loading({
            message: '正在删除...',
            forbidClick: true,
        });
        axios.delete(url, data)
            .then(response => {
                resolve(response.data);
                Toast.clear();
            }, err => {
                /*msag();*/
                reject(err)
            })
    })
}

/**
 * 封装put请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        Toast.loading({
            message: '正在更新...',
            forbidClick: true,
        });
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
                Toast.clear();
            }, err => {
                /*   msag();*/
                reject(err)
            })
    })
}


//失败提示
function msag() {
    Message({
        message: "网络请求失败",
        type: 'warning'
    });
}



