<template>
  <div id="app" @click="clickDiv">
    <!--页面不刷新方法keep-alive-->
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <!-- <router-view v-if="$route.meta.keepAlive"></router-view>-->
    <router-view v-if="!$route.meta.keepAlive"></router-view>
    <div id="viewer"></div>
    <div id="htmlContainer" style="position: absolute; top: -4000px"></div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      lastTime: null, //最后一次点击时间
      currentTime: null, //当前点击时间
      timeInterval: "",
      refreshToken: null,
    };
  },
  created() {
    // 获取当前页面的完整 URL（包括查询字符串）
    const url = new URL(window.location);

    // 创建 URLSearchParams 对象并传入 URL 作为参数
    const params = new URLSearchParams(url.search);

    // 定义空对象来存储转换后的结果
    let result = {};

    // 遍历所有的 URL 参数
    params.forEach((value, key) => {
      // 如果已经存在相同名称的属性，则将其值转换成数组形式
      if (result[key]) {
        const currentValue = Array.isArray(result[key])
          ? result[key]
          : [result[key]];
        result[key] = [...currentValue, value];
      } else {
        // 否则直接添加到结果对象中
        result[key] = value;
      }
    });
    console.log(result);
    if (result.storeId) {
      localStorage.setItem("storeId", result.storeId);
      localStorage.setItem("storeName", result.storeName);
      localStorage.setItem("token", result.token);
      localStorage.setItem("terminalId", result.terminalId);
    }
  },
  mounted() {
    this.refreshToken = setInterval(() => {
      this.getToken();
    }, 30 * 60 * 1000);
  },
  methods: {
    getToken() {
      this.$put("/user/1.0/Login").then((res) => {
        console.log("更新Token");
      });
    },
    clickDiv() {
      if (this.timeInterval == "") {
        // 5秒钟检测一次
        this.timeInterval = setInterval(this.isTimeOut, 5000);
      }
      this.lastTime = new Date().getTime();
    },
    isTimeOut() {
      this.currentTime = new Date().getTime(); //当前时间
      // 判断上次最后一次点击的时间和这次点击的时间间隔是否大于规定的时间,例:10分钟  当前3分钟
      if (this.currentTime - this.lastTime > 180000) {
        //判断是否为登录状态,只有登录状态下才调用方法
        var token = localStorage.getItem("token");
        if (token) {
          localStorage.setItem("keyCard", "");
          clearInterval(this.timeInterval);
          this.timeInterval = "";
          var type = localStorage.getItem("type");
          var sex = localStorage.getItem("sex");
          console.log(type);
          if (type == "自助结账") {
            this.$router.push("/welcome"); //先返回首页
          } else if (type == '自助开牌') {
            this.$router.push({
              path: "/codeBox",
              query: {
                sex: sex,
              },
            });
          }else{
            this.$router.push('/prepayment')
          }
        }
      }
    },
  },
};
</script>

<style lang="less">
#viewer {
  width: 0 !important;
  height: 0 !important;
  min-width: 0 !important;
  min-height: 0 !important;
  overflow: hidden;
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video,
input {
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: 400;
  font-family: PingFangSC-Regular, "PingFangSC", MicrosoftYaHei Regular,
    "微软雅黑", Helvetica, Arial, sans-serif;
  vertical-align: baseline;
  outline: none;
}
html,
body {
  width: 100%;
  height: 100%;
}
#app {
  font-weight: 400;
  font-family: PingFangSC-Regular, "PingFangSC", MicrosoftYaHei Regular,
    "微软雅黑", Helvetica, Arial, sans-serif;
  width: 100%;
  height: 100%;
  background-image: url("../src/assets/bg.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
div {
  box-sizing: border-box;
}
.flex {
  display: flex;
}
.flex-column {
  display: flex;
  flex-direction: column;
}
.flex-center {
  align-items: center;
  justify-content: center;
}
.flex-sb {
  justify-content: space-between;
}
</style>
