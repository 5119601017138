import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import Vant from 'vant'
import 'vant/lib/index.css';
import {Toast} from 'vant';
Vue.use(Vant);
Vue.use(Toast);
import Api from './utils/api.js'
//网络请求
import {post, fetch, fetchNo, put, deletes} from './utils/request'
//定义全局变量
Vue.prototype.$post = post;//发
Vue.prototype.$fetch = fetch;//取
Vue.prototype.$fetchNo = fetchNo;  //不待loading get
Vue.prototype.$put = put;//更新 更新
Vue.prototype.$deletes = deletes;//更新 更新
Vue.prototype.Api = Api;
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
